import NotFound from "../components/Notification pages/404";
import AdminHome from "../components/Admin/Dashboard/AdminHome";
import Session from "../components/Admin/session/Session";
import Plan from "../components/Admin/Subscription plan/Plan";
import AdminCourses from "../components/Admin/Admin course/AdminCourses";
import PaymentManagement from "../components/Admin/payment-management/PayementManagement";
import PaymentManagementCourse from "../components/Admin/payment-management-course/PaymentManagementCourse";
import AdminCourseDetails from "../components/Admin/Admin course/AdminCourseDetails";
import Currency from "../components/Admin/Currency/Currency";
import Channel from "../components/Admin/Channel/Channel";
import Resources from "../components/Admin/Resource/Resources";
import SessionDetails from "../components/Admin/session/SessionDetails";
import AdminUserProfile from "../components/Admin/Profile/AdminUserProfile";
import { APP_ROLES } from "../network/auth/Auth";
import SessionManager from "../components/Admin/session/SessionManager";
import UserTransactionCourse from "../components/Admin/UserTransactionCourse/UserTransactionCourse";
import UserAdministration from "../components/Admin/User administration/UserAdministration";
import AlreadySubscribePage from "../components/Notification pages/AlreadySubscripePage";
import CourseDetailsWordpress from "../components/home/CourseDetailsWorpress";
import Footer from "../components/footer/Footer";
import AdminNewsletterSubscribers from "../components/Admin/Newsletter/AdminNewsletterSubscribers";
import AddSubscription from "../components/Admin/Manual subscription/AddSubscription";
import TestToggle from "../components/user/Profile/TestToggle";
import SubscriptionIntent from "../components/Admin/subscription-intent/SubscriptionIntent";
import SubscriptionIntentDetails from "../components/Admin/subscription-intent/SubscriptionIntentDetails";
import LogoutUrl from "../components/user/Profile/LogoutUrl";
import UserTransaction from "../components/Admin/Users Transactions/UserTransaction";
import TransactionLogs from "../components/Admin/Transaction logs/Transaction";
import SimpleTransaction from "../components/Admin/Transaction logs/SimpleTransaction";
import SearchUser from "../components/Admin/Transaction logs/SearchUser";
import Home from "../pages/space/user/Home";
import OpenedUnsecuredSessions from "../pages/common/OpenedUnsecuredSessions";
import OpenedUnsecuredSessionsDetails from "../pages/common/OpenedUnsecuredSessionsDetails";
import MySessionDetails from "../pages/space/user/my-sessions/MySessionDetails";
import AvalaibleCourses from "../pages/space/user/courses/AvalaibleCourses";
import AvalaibleCoursesDetails from "../pages/space/user/courses/AvalaibleCoursesDetails";
import AvalailbleCourseCheckout from "../pages/space/user/courses/AvalailbleCourseCheckout";
import AvalaibleCourseCheckoutSuccess from "../pages/space/user/courses/AvalaibleCourseCheckoutSuccess";
import MyCoursesDetails from "../pages/space/user/courses/my-courses/MyCoursesDetails";
import OpenedSessions from "../pages/space/user/opened-sessions/OpenedSessions";
import OpenedSessionDetails from "../pages/space/user/opened-sessions/OpenedSessionDetails";
import OpenedSessionCheckOut from "../pages/space/user/opened-sessions/OpenedSessionCheckOut";
import OpenedSessionSuccessFullyCheckOut from "../pages/space/user/opened-sessions/OpenedSessionSuccessFullyCheckOut";
import OpenedSessionSuccessFullyCheckOutApp from "../pages/space/user/opened-sessions/OpenedSessionSuccessFullyCheckOutApp";
import NextSession from "../pages/space/user/next-sessions/NextSession";
import NextSessionDetails from "../pages/space/user/next-sessions/NextSessionDetails";
import MyTransaction from "../pages/space/user/my-transaction/MyTransaction";
import MyTransactionDetails from "../pages/space/user/my-transaction/MyTransactionDetails";
import Components from "../components/frontv2.0/common/Components";
import SignUp from "../pages/auth/signup/SignUp";
import SignIn from "../pages/auth/signin/SignIn";
import ForgottenPass from "../pages/auth/lostpassword/ForgottenPass";
import NewPassword from "../pages/auth/resetpassword/NewPassword";
import Admin2FA from "../pages/auth/confirm-identity/Admin2FA";
import Page404 from "../pages/errors/Page404";
import PageErrorPaymentIncomplete from "../pages/errors/PageErrorPaymentIncomplete";
import PageStatusAlreadySubscription from "../pages/errors/PageStatusAlreadySubscription";
import MySessions from "../pages/space/user/my-sessions/MySessions";
import MyCourses from "../pages/space/user/courses/my-courses/MyCourses";
import MakePaymentDetails from "../pages/space/user/make-payment/MakePaymentDetails";
import MakePayment from "../pages/space/user/make-payment/MakePayment";
import Profile from "../pages/space/user/profile/Profile";
import AskDelay from "../pages/space/user/promotion/AskDelay";
import AlreadyDeferment from "../pages/space/user/promotion/AlreadyDeferment";
import AlreadyTwoReports from "../pages/space/user/promotion/AlreadyTwoReports";
import AskDiscount from "../pages/space/user/promotion/AskDiscount";
import EmailConfirmation from "../pages/auth/email-confirmation/EmailConfirmation";
import OpenedSessionCheckOutApp from "../pages/space/user/opened-sessions/OpenedSessionCheckOutApp";
import DiscountSuccess from "../pages/space/user/promotion/DiscountSuccess";
import AuthSetup from "../components/Admin/Auth setup/AuthSetup";
import AccountSynchronizationPanel from "../components/Admin/Account synchronization panel/AccountSynchronizationPanel";
import ConfigDelayAndDiscount from "../components/Admin/ConfigDelayAndDiscount/ConfigDelayAndDiscount";
import DiscountCode from "../components/Admin/Promotion code/DiscountCode";


const routes = [
  // Common Routes
  {
    spaceName: "common",
    secure: false,
    routes: [
      {
        component: <Home />,
        path: "/",
      },
      {
        component: <OpenedUnsecuredSessions />,
        path: "/open-sessions",
      },
      {
        component: <OpenedUnsecuredSessionsDetails />,
        path: "/open-sessions/details/:sessionId",
      },

      {
        component: <OpenedUnsecuredSessions />,
        path: "/available-courses",
      },
      {
        component: <OpenedUnsecuredSessionsDetails />,
        path: "/available-courses/details/:sessionId",
      },

      {
        component: <NotFound />,
        path: "/error",
      },
      {
        component: <AlreadySubscribePage />,
        path: "/error-subscription",
      },
      {
        component: <NotFound />,
        path: "",
      },
      {
        component: <EmailConfirmation />,
        path: "confirmation",
      },
      {
        component: <CourseDetailsWordpress />,
        path: "/courses-details-check",
      },
      {
        component: <Footer />,
        path: "footer",
      },
      {
        component: <TestToggle />,
        path: "toggle",
      },
      {
        component: <LogoutUrl />,
        path: "logout-url",
      },
    ],
  },
  // User Workspace Routes
  {
    spaceName: "user-workspace",
    secure: true,
    role: APP_ROLES.ROLE_USER,
    routes: [
      // {
      //   component: <MySessions />,
      //   path: "/workspace",
      // },
      // {
      //   component: <MySessions />,
      //   path: "/workspace/mysessions",
      // },
      {
        path: "/workspace",
        component: <MySessions url={process.env.REACT_APP_COURSE_LMS + "/my-sessions"} />
      },
      {
        path: "/workspace/mysessions",
        component: <MySessions url={process.env.REACT_APP_COURSE_LMS + "/my-sessions"} />
      },
      {
        component: <MySessionDetails />,
        path: "/workspace/mysessions/details",
      },

      // Routes Secure Avalaible Courses
      {
        component: <AvalaibleCourses />,
        path: "/workspace/courses",
      },

      {
        component: <AvalaibleCoursesDetails />,
        path: "/workspace/courses/details/:courseId",
      },

      {
        component: <AvalailbleCourseCheckout />,
        path: "/workspace/courses/details/checkout/:courseId/:planId",
      },

      {
        component: <AvalaibleCourseCheckoutSuccess />,
        path: "/workspace/courses/details/checkout/success",
      },

      // {
      //   component: <MyCourses />,
      //   path: "/workspace/mycourses",
      // },
      {
        path: "/workspace/mycourses",
        component: <MyCourses url={process.env.REACT_APP_COURSE_LMS + "/my-courses"} />
      },


      {
        component: <MyCoursesDetails />,
        path: "/workspace/mycourses/details",
      },

      // Routes Secure Opened Sessions
      // {
      //   component: <OpenedSessions />,
      //   path: "/workspace/opened-sessions",
      // },

      // {
      //   component: <OpenedSessionDetails />,
      //   path: "/workspace/opened-sessions/details/:sessionId",
      // },

      {
        component: <OpenedSessionCheckOutApp />,
        path: "/open-sessions/details/checkout/:sessionId/:planId",
      },

      {
        component: <OpenedSessionSuccessFullyCheckOutApp />,
        path: "/open-sessions/details/checkout/success",
      },

      // Routes Secure Next Sessions
      {
        component: <NextSession />,
        path: "/workspace/next-sessions",
      },

      {
        component: <NextSessionDetails />,
        path: "/workspace/next-sessions/details/:sessionId",
      },

      {
        component: <OpenedSessionCheckOut />,
        path: "/workspace/next-sessions/details/checkout/:sessionId/:planId",
      },


      {
        component: <OpenedSessionSuccessFullyCheckOut />,
        path: "/workspace/next-sessions/details/checkout/success",
      },

      {
        component: <MakePayment />,
        path: "/workspace/make-payment",
      },

      {
        component: <MakePaymentDetails />,
        path: "/workspace/make-payment/details",
      },

      {
        component: <MyTransaction />,
        path: "/workspace/transactions",
      },
      {
        component: <MyTransactionDetails />,
        path: "/workspace/transactions/details",
      },

      {
        component: <Profile />,
        path: "/workspace/profile",
      },

      {
        component: <AskDiscount />,
        path: "/workspace/ask-discount",
      },
      {
        component: <AlreadyTwoReports />,
        path: "/workspace/AlreadyTwoReports",
      },
      {
        component: <AskDelay />,
        path: "/workspace/delay",
      },
      {
        component: < AlreadyDeferment />,
        path: "/workspace/Postponing",
      },

      {
        component: <  AlreadyTwoReports />,
        path: "/workspace/AlreadyTwoReports",
      },


      {
        component: <Components />,
        path: "/components",
      },
      {
        component: <DiscountSuccess />,
        path: "/workspace/ask-discount/success/:subscriptionId/:sessionId",
      },
    ],
  },

  // Authentication Routes
  {
    spaceName: "auth",
    // spaceName: "login",
    secure: false,
    routes: [
      {
        component: <SignUp />,
        path: "/register",
      },
      {
        component: <SignIn />,
        path: "/login",
      },
      {
        component: <ForgottenPass />,
        path: "/lostpassword",
      },
      {
        component: <NewPassword />,
        path: "/resetpassword",
      },
      {
        component: <Admin2FA />,
        path: "/confirm-identity",
      },
    ],
  },

  // Manager Routes
  {
    spaceName: "manager",
    secure: true,
    role: APP_ROLES.ROLE_MANAGER,
    routes: [
      {
        component: <AdminHome />,
        path: "/admin",
      },
      {
        component: <Session />,
        path: "/sessions",
      },
      {
        component: <Channel />,
        path: "/channels",
      },

      {
        component: <Currency />,
        path: "/currency",
      },

      {
        component: <TransactionLogs />,
        path: "/transactions",
      },
    ],
  },
  // Instructor
  //  {
  //   spaceName: "instructor",
  //   secure: true,
  //   role: APP_ROLES.ROLE_INSTRUCTOR,
  //   routes: [
  //     {
  //       component: <AdminHome />,
  //       path: "/admin",
  //     },
  //     {
  //       component: <UserTransactionCourse />,
  //       path: "userstransaction",
  //     },

  //     {
  //       component: <UserTransaction />,
  //       path: "users_transaction",
  //     },

  //     {
  //       component: <PaymentManagement />,
  //       path: "/payment_management",
  //     },

  //     {
  //       component: <PaymentManagementCourse />,
  //       path: "/payment_management-course",
  //     },
  //     {
  //       component: <SubscriptionIntent />,
  //       path: "/subscription-intent",
  //     },
  //     {
  //       component: <SubscriptionIntentDetails />,
  //       path: "/subscription-intent-details",
  //     },
  //     {
  //       component: <AddSubscription />,
  //       path: "add-subscription",
  //     },
  //     {
  //       component: <Plan />,
  //       path: "/plans",
  //     },
  //     {
  //       component: <AdminCourses />,
  //       path: "/admin-courses",
  //     },
  //     {
  //       component: <AdminCourseDetails />,
  //       path: "/admin-courses-details",
  //     },
  //     {
  //       component: <SearchUser />,
  //       path: "/search-user",
  //     },
  //     {
  //       component: <Session />,
  //       path: "/sessions",
  //     },
  //     {
  //       component: <AuthSetup />,
  //       path: "/configure-login-register",
  //     },
  //     {
  //       component: <SessionDetails />,
  //       path: "/session-details",
  //     },
  //   ],
  // },
  //course
  {
    spaceName: "course",
    secure: true,
    role: APP_ROLES.ROLE_COURSE,
    routes: [
      {
        component: <Resources />,
        path: "/admin-resources",
      },
      {
        component: <AdminCourses />,
        path: "/admin-courses",
      },
      // {
      //     component: <AdminCourses />,
      //     path: '/admin-courses',
      // },
      {
        component: <AdminCourseDetails />,
        path: "/admin-courses-details",
      },
      {
        component: <SessionDetails />,
        path: "/session-details",
      },
    ],
  },

  // Accounting routes
  {
    spaceName: "accounting",
    secure: true,
    role: APP_ROLES.ROLE_ACCOUNTING,
    routes: [
      {
        component: <AdminHome />,
        path: "/admin",
      },
      {
        component: <UserTransactionCourse />,
        path: "userstransaction",
      },

      {
        component: <UserTransaction />,
        path: "users_transaction",
      },

      {
        component: <PaymentManagement />,
        path: "/payment_management",
      },

      {
        component: <PaymentManagementCourse />,
        path: "/payment_management-course",
      },
      {
        component: <SubscriptionIntent />,
        path: "/subscription-intent",
      },
      {
        component: <SubscriptionIntentDetails />,
        path: "/subscription-intent-details",
      },
      {
        component: <AddSubscription />,
        path: "add-subscription",
      },
      {
        component: <Plan />,
        path: "/plans",
      },
      {
        component: <SearchUser />,
        path: "/search-user",
      },
      {
        component: <AuthSetup />,
        path: "/configure-login-register",
      },

    ],
  },


  // Admin Routes
  {
    spaceName: "admin",
    secure: true,
    role: APP_ROLES.ROLE_ADMIN,
    routes: [
      {
        component: <SessionManager />,
        path: "/admin-session-manager",
      },
      // {
      //     component: <UsersTransaction />,
      //     path: 'users_transaction',
      // },
      {
        component: <UserAdministration />,
        path: "users_administration",
      },
      {
        component: <AdminNewsletterSubscribers />,
        path: "newsletter-subscribers",
      },
      {
        component: <AdminUserProfile />,
        path: "admin-profile-user/:id",
        exact: false,
      },
      {
        component: <AdminUserProfile />,
        path: "admin-profile-user",
        exact: false,
      },
      {
        component: <DiscountCode />,
        path: "discount-coupons",
      },
      {
        component: <AccountSynchronizationPanel />,
        path: "/account-synchronization",
      },

      {
        component: <ConfigDelayAndDiscount />,
        path: "/config-delay-discount",
      },

    ],
  },

  // Support Routes
  {
    spaceName: "support",
    secure: true,
    role: APP_ROLES.ROLE_SUPPORT,
    routes: [
      {
        component: <SimpleTransaction />,
        path: "/transaction",
      },
      {
        component: <SearchUser />,
        path: "/search-user",
      },
      {
        component: <AuthSetup />,
        path: "/configure-login-register",
      },
    ],
  },

  // Errors and Alerts Routes
  {
    spaceName: "errors-alert",
    secure: false,
    routes: [
      {
        component: <Page404 />,
        path: "*",
      },
      {
        component: <PageErrorPaymentIncomplete />,
        path: "/status/incomplete-payment",
      },
      {
        component: <PageStatusAlreadySubscription />,
        path: "/status/already-subscription",
      },
    ],
  },
];
export default routes;
