import Logo from "../logo/Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FAVICON_WITHE, LOGO_WHITE } from "../../../../constants/images";
import routes from "../../../../routes/routes";
import { useEffect, useState } from "react";
import { logoutRedirectUrl } from "../../../../constants/Constants";
import UserService from "../../../../network/services/UserService";
import { isDiscountEligibility, saveDiscountEligibility } from "../../../../network/auth/Auth";
import { IS_ELIGIBLE_DISCOUNT_DATE } from "../../../../network/auth/Auth";

const AsideMenu = ({ isDesktop, expanded, toggleExpanded }) => {
    /**
     * States
     */
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState(location?.pathname);
    const navigate = useNavigate();
    function checkDiscountEligibility(objects) {
        for (let i = 0; i < objects.length; i++) {
            if (!objects[i].isEligibleToDiscount) {
                return {
                    reason: objects[i].discountIneligibilityReason,
                    isEligibleToDiscount: false
                };
            }
        }
        return { isEligibleToDiscount: true }
    }
    const getAllSubscribed = () => {
        const result = isDiscountEligibility();
        if (result) {
            if (result.isEligibleToDiscount === false && result.reason !== 'CANCELLED') {
                let expiresAt = JSON.parse(localStorage.getItem(IS_ELIGIBLE_DISCOUNT_DATE));

                let now = (new Date()).getTime();
                if (now > Number(expiresAt)) {
                    UserService.getAllSessions((response) => {
                        if (response) {
                            if (response.success) {
                                if (response?.data && response.data.length > 0) {
                                    let test = checkDiscountEligibility(response?.data);
                                    saveDiscountEligibility(test);
                                }
                            }
                        }
                    })
                }

            } else if (result.isEligibleToDiscount !== false ||
                (result.isEligibleToDiscount === false &&
                    result.reason === 'CANCELLED')) {
                UserService.getAllSessions((response) => {
                    if (response) {
                        if (response.success) {
                            if (response?.data && response.data.length > 0) {
                                let test = checkDiscountEligibility(response?.data);
                                saveDiscountEligibility(test);
                            }
                        }
                    }
                })
            }
        } else {
            UserService.getAllSessions((response) => {
                if (response) {
                    if (response.success) {
                        if (response?.data && response.data.length > 0) {
                            let test = checkDiscountEligibility(response?.data);
                            saveDiscountEligibility(test);
                        }

                    }
                }
            })
        }
    }

    const handleLogOut = (e) => {
        e.preventDefault();
        logoutRedirectUrl();

        // logout();
        // navigate("/");
    };
    /**
     * Comportments
     */
    let allRoutes = [];
    routes.map(({ spaceName, secure, role, routes }) => {
        return routes.map(({ path, component }) => {
            return allRoutes.push({
                path: path,
                component: component,
                role: role,
                secure: secure,
            });
        });
    });

    /**
     * Hooks
     */
    useEffect(() => {
        getAllSubscribed();
        setCurrentRoute(location.pathname.split("/").slice(0, 3).join("/"));
    }, [location.pathname]);
    /**
     * Render
     */
    const isEligible = isDiscountEligibility() ? (isDiscountEligibility()).isEligibleToDiscount : false;

    return (
        <>
            <aside
                className={`tw-flex tw-flex-col tw-w-full tw-h-full tw-px-2 tw-py-4 tw-bg-primary tw-transition-all tw-duration-500`}
            >
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-between ">
                    <Link to="/" className={`${expanded ? "" : "tw-pl-10"}`}>
                        {expanded ? (
                            <Logo src={FAVICON_WITHE} classes={"tw-h-[29px]"} />
                        ) : (
                            <Logo src={LOGO_WHITE} />
                        )}
                    </Link>
                    <button
                        className="tw-flex tw-items-center tw-justify-center tw-ml-1 tw-text-white hover:tw-text-gray-300 focus:tw-outline-none"
                        onClick={toggleExpanded}
                    >
                        {isDesktop && expanded ? (
                            <i
                                className="pi pi-angle-double-right"
                                style={{ fontSize: "1rem" }}
                            ></i>
                        ) : (
                            <i
                                className="pi pi-angle-double-left"
                                style={{ fontSize: "1rem" }}
                            ></i>
                        )}
                    </button>
                </div>

                <div
                    className="tw-flex tw-overflow-y-auto tw-flex-col tw-justify-between tw-flex-1 tw-mt-[59px] tw-transition tw-ease-in-out tw-delay-100 tw-duration-200">
                    <nav>
                        <div
                            className={`${expanded
                                    ? " tw-justify-center tw-text-[6px] "
                                    : "tw-justify-start tw-pl-[10px] tw-text-[12px] "
                                } tw-flex tw-h-[18px] sm:tw-mb-3 tw-truncate ...`}
                        >
                            <span className="tw-text-[#C2C2C2] tw-uppercase">Main Menu</span>
                        </div>
                      
                        <Link
                            className={`tw-flex tw-items-center tw-px-[10px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/mysessions" ===
                                    allRoutes.find((item) => {
                                        return item?.path === currentRoute;
                                    })?.path ||
                                    "/workspace" ===
                                    allRoutes.find((item) => {
                                        return item?.path === currentRoute;
                                    })?.path
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            // to="/workspace/mysessions"
                            to={process.env.REACT_APP_COURSE_LMS + "/my-sessions"}                        >
                            <i
                                tabIndex="0"
                                data-toggle="tooltip"
                                title="My sessions"
                                className="pi pi-calendar"
                                style={{ fontSize: "1.32rem" }}
                            ></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    My sessions
                                </span>
                            )}
                        </Link>
                        {process.env.REACT_APP_SIMPLE_COURSE === 'OPENED' ?
                            <Link
                                className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/mycourses" ===
                                        allRoutes.find((item) => {
                                            return item?.path === currentRoute;
                                        })?.path
                                        ? " tw-bg-primary-200 tw-text-primary"
                                        : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                    } `}
                                // to="/workspace/mycourses"
                                to={process.env.REACT_APP_COURSE_LMS + "/my-courses"}
                            >
                                <i
                                    tabIndex="0"
                                    data-toggle="tooltip"
                                    title="My Courses"
                                    className="pi pi-book"
                                    style={{ fontSize: "1.32rem" }}
                                ></i>
                                {!expanded && (
                                    <span
                                        className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                    >
                                        My courses
                                    </span>
                                )}
                            </Link> : ""}

                        <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/next-sessions" ===
                                    allRoutes.find((item) => {
                                        return item?.path === currentRoute;
                                    })?.path
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/next-sessions"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title="Next sessions" className="pi pi-clock" style={{ fontSize: "1.32rem" }}></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    Next sessions
                                </span>
                            )}
                        </Link>
                        {process.env.REACT_APP_SIMPLE_COURSE === 'OPENED' ?
                            <Link
                                className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/courses" ===
                                        allRoutes.find((item) => {
                                            return item?.path === currentRoute;
                                        })?.path
                                        ? " tw-bg-primary-200 tw-text-primary"
                                        : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                    } `}
                                to="/workspace/courses"
                            >
                                <i
                                    tabIndex="0"
                                    data-toggle="tooltip"
                                    title="Available Courses"
                                    className="pi pi-check-circle"
                                    style={{ fontSize: "1.32rem" }}
                                ></i>
                                {!expanded && (
                                    <span
                                        className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                    >
                                        Available courses
                                    </span>
                                )}
                            </Link> : ""}

                        <div
                            className={`${expanded
                                    ? " tw-justify-center tw-text-[6px]"
                                    : "tw-justify-start tw-pl-[10px]  tw-text-[12px]"
                                } tw-flex tw-pb-[18px] tw-pt-[12px] tw-h-[24px] tw-mb-1`}
                        >
                            <span className="tw-text-[#C2C2C2] tw-uppercase">Payments</span>
                        </div>

                        <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mt-2  tw-rounded-md  ${"/workspace/transactions" ===
                                    allRoutes.find((item) => {
                                        return item?.path === currentRoute;
                                    })?.path
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/transactions"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title="My transactions"
                                className="pi pi-credit-card"
                                style={{ fontSize: "1.32rem" }}
                            ></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    My transactions
                                </span>
                            )}
                        </Link>

                        <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-mt-3  tw-rounded-md  ${"/workspace/make-payment" ===
                                    allRoutes.find((item) => {
                                        return item?.path === currentRoute;
                                    })?.path
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/make-payment"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title="Make a payment" className="pi pi-wallet" style={{ fontSize: "1.32rem" }}></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    Make a payment
                                </span>
                            )}
                        </Link>

                        <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mt-2  tw-rounded-md  ${"/workspace/profile?tab=2" === currentRoute
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/profile?tab=2"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title="Payments method" className="pi pi-dollar" style={{ fontSize: "1.32rem" }}></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    Payment method
                                </span>
                            )}
                        </Link>

                        <div
                            className={`${expanded
                                    ? " tw-justify-center tw-text-[6px]"
                                    : "tw-justify-start tw-pl-[10px]  tw-text-[12px]"
                                } tw-flex tw-pb-[18px] tw-pt-[12px] tw-h-[24px] tw-mb-1`}
                        >
                            <span className="tw-text-[#C2C2C2] tw-uppercase">Security</span>
                        </div>
                        <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-mt-3  tw-rounded-md  ${"/workspace/profile?tab=1" === currentRoute
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/profile?tab=1"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title="Change password" className="pi pi-wallet" style={{ fontSize: "1.32rem" }}></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    Change password
                                </span>
                            )}
                        </Link>
                        <div
                            className={`${expanded
                                    ? " tw-justify-center tw-text-[6px]"
                                    : "tw-justify-start tw-pl-[10px]  tw-text-[12px]"
                                } tw-flex tw-pb-[18px] tw-pt-[12px] tw-h-[24px] tw-mb-1`}
                        >
                            <span className="tw-text-[#C2C2C2] tw-uppercase">Promotion</span>
                        </div>

                        {isEligible && <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/ask-discount" ===
                                    allRoutes.find((item) => {
                                        return item?.path === currentRoute;
                                    })?.path
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/ask-discount"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title="Ask a discount" className="pi pi-money-bill" style={{ fontSize: "1.32rem" }}></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    Ask a discount
                                </span>
                            )}
                        </Link>}

                        <Link
                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-mt-3  tw-rounded-md  ${"/workspace/delay" === currentRoute
                                    ? " tw-bg-primary-200 tw-text-primary"
                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                } `}
                            to="/workspace/delay"
                        >
                            <i tabIndex="0"
                                data-toggle="tooltip"
                                title=" Ask a delay" className="pi pi-calendar-plus" style={{ fontSize: "1.32rem" }}></i>
                            {!expanded && (
                                <span
                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                >
                                    Postpone payment date
                                </span>
                            )}
                        </Link>
                    </nav>
                </div>


                <hr className="tw-my-3 tw-border-gray-200" />
                <Link
                    className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2  tw-rounded-md  ${"/workspace/profile" ===
                            allRoutes.find((item) => {
                                return item?.path === currentRoute;
                            })?.path
                            ? " tw-bg-primary-200 tw-text-primary"
                            : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                        } `}
                    to="/workspace/profile?tab=0"
                >
                    <i tabIndex="0"
                        data-toggle="tooltip"
                        title="My Profile" className="pi pi-user" style={{ fontSize: "1.32rem" }}></i>
                    {!expanded && (
                        <span
                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                        >
                            My Profile
                        </span>
                    )}
                </Link>
                <Link
                    className="tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-text-red-400 tw-transition-colors tw-duration-300 tw-transform tw-rounded-md hover:bg-red-200 hover:tw-text-red-700"
                    onClick={handleLogOut}
                >
                    <i tabIndex="0"
                        data-toggle="tooltip"
                        title="Logout" className="pi pi-sign-out" style={{ fontSize: "1.32rem" }}></i>
                    {!expanded && (
                        <span
                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                        >
                            Logout
                        </span>
                    )}
                </Link>
            </aside>
        </>
    );
};

export default AsideMenu;
