import React, {useEffect, useRef, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import UserService from "../../../network/services/UserService";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";
import PlanList from "./Filter components/PlanList";
import MultiSelectItem from "./Filter components/MultiselectItem";
import CourseStatistics from "./Statistics/CourseStatistics";

const UserTransactionCourse = () => {

    const childCompRef = useRef();
    const childCompRef2 = useRef();

    //const currentNumberElements = (currentPage * itemPerPage);
    //const start = 1 + ((currentPage * itemPerPage) - itemPerPage);
    // const limit = (currentNumberElements < totalItems) ? currentNumberElements : totalItems;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [lastPage, setLastPage] = useState(1);
    const [course, setCourse] = useState(null);
    const [courseId, setCourseId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementPerPage, setElementPerPage] = useState(10);
    const [subscriptions, setSubscriptions] = useState([]);
    const [planId, setPlanId] = useState(null);
    const [researchIndicator, setResearchIndicator] = useState('');
    const [userDetails, setUserDetails] = useState([]);

    const openDetailsTransaction = (e) => {
        const queryParams = new URLSearchParams(window.location.search);
        let courId = queryParams.get('courseId');
        const id = e.target.getAttribute("id");
        navigate({
            pathname: "/payment_management-course",
            search: '?courseId=' + courId + '&userId=' + id
        })
    }


    
    const showTransactions = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('courseId');

        setCourseId(id);
        setIsLoading(true);

        let data = {
            page: currentPage,
            search: researchIndicator,
            numberPerPage: elementPerPage,
            subscriptionPlanId: planId,
            //  courseId: parseInt(id),
        }

        UserService.showCourseSubscription(parseInt(id), data, (response) => {
            if (response) {
                if (response.success) {
                    setCourse(response.result[0]?.product);
                    setCurrentPage(1);
                    setSubscriptions(response.result);
                    setLastPage(Math.ceil(response.total / elementPerPage));
                }
            }
            setIsLoading(false)
        });
    }

    const handlePageClick = (pg) => {

        let pageNumber = pg.selected + 1;

        setIsLoading(true);

        let data = {
            page: pageNumber,
            search: researchIndicator,
            numberPerPage: elementPerPage,
            subscriptionPlanId: planId,
            courseId: courseId,
        }

        UserService.showCourseSubscription(courseId, data, (response) => {

            if (response) {
                if (response.success) {
                    setCourse(response.result[0]?.product);
                    setCurrentPage(1);
                    setSubscriptions(response.result);
                    setLastPage(Math.ceil(response.total / elementPerPage));
                }
            }
            setIsLoading(false)
        });

        //
        // UserService.showSubscription(data, (response) => {
        //     if (response) {
        //         setCourse(response.result[0]?.course);
        //         setCurrentPage(response.page);
        //         setSubscriptions(response.result);
        //         setLastPage(Math.ceil(response.total / elementPerPage))
        //     }
        //     setIsLoading(false)
        // });
    }

    const clearAll = () => {
        setPlanId(null);
        setResearchIndicator('');
        childCompRef.current?.clear();
        setElementPerPage(10);
        setCurrentPage(1);
        let select_box = document.getElementById("myselectbox");
        select_box.selectedIndex = 1;
        setIsLoading(true);
        let data = {
            page: 1,
            search: '',
            numberPerPage: 10,
            subscriptionPlanId: '',
            //courseId: courseId,
        }

        UserService.showCourseSubscription(courseId, data, (response) => {
            if (response) {
                if (response.success) {
                    setCourse(response.result[0]?.product);
                    setCurrentPage(1);
                    setSubscriptions(response.result);
                    setLastPage(Math.ceil(response.total / elementPerPage));
                }
            }
            setIsLoading(false)
        });
    }

    /*This function is used to parse data in the format accept by the Excel file generator*/
    const transformData = (data) => {

        const categoryIds = childCompRef2.current?.getValue();

        const tabData = [];
        data.forEach(element => {
            let details = null;

            if (categoryIds.includes('All')) {
                details = {
                    name: element.name,
                    email: element.email,
                    price: element.price,
                    paid: element.actualPaidAmount,
                    expected: element.expectedPaidAmount,
                    remaining: element.remainingAmount,
                    date: moment(element.created_at).utc().format('DD-MM-YYYY') + ' at ' + moment.utc(element.created_at).format("HH:mm"),
                    phone: element.phone,
                    accessType: element.accessType,
                    standing: element.isInGoodStanding,
                    reference: element.reference,
                }
            } else {


                if (categoryIds.includes('User')) {
                    details = {...details, name: element.name}
                }
                if (categoryIds.includes('Email')) {
                    details = {...details, email: element.email}
                }
                if (categoryIds.includes('Cell phone')) {
                    details = {...details, phone: element.phone}
                }
                if (categoryIds.includes('Total paid')) {
                    details = {...details, paid: element.actualPaidAmount}
                }
                if (categoryIds.includes('Subscription price')) {
                    details = {...details, price: element.price}
                }
                if (categoryIds.includes('Expected to pay')) {
                    details = {...details, expected: element.expectedPaidAmount}
                }
                if (categoryIds.includes('remaining to pay')) {
                    details = {...details, remaining: element.remainingAmount}
                }
                if (categoryIds.includes('Access type')) {
                    details = {...details, accessType: element.accessType}
                }
                if (categoryIds.includes('Course access')) {
                    const status = element.isInGoodStanding ? 'Access to course' : 'No access to course';
                    details = {...details, standing: status}
                }
                if (categoryIds.includes('Subscription reference')) {
                    details = {...details, reference: element.reference}
                }
                if (categoryIds.includes('Payment date')) {
                    details = {
                        ...details,
                        date: moment(element.created_at).utc().format('DD-MM-YYYY') + ' at ' + moment.utc(element.created_at).format("HH:mm")
                    }
                }
            }
            if (details !== null) {
                tabData.push(details);
            }
        })
        return tabData;
    }
    //
    // const exportData = () => {
    //     setUserDetails(transformData);
    //     if (this._export !== null) {
    //         this._export.save();
    //     }
    // };

    const _export = React.useRef(null);

    const computeData = () => {
        // console.log("les données données")
        // console.log(subscriptions);
        setUserDetails(transformData(subscriptions));
    }

    const exportData = () => {

        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const exportInExcel = (data) => {
        const categoryIds = childCompRef2.current?.getValue();
        const isEmpty = data.length < 1;
        if (categoryIds) {
            return <ExcelExport data={data} ref={_export}>
                {/*<ExcelExport data={userDetails} ref={exporter => this._export = exporter}>*/}

                <Grid data={data}
                      style={{
                          height: '420px'
                      }}>
                    <GridToolbar>
                        <button title="Export Excel"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={exportData}
                                disabled={isEmpty}
                        >
                            Export to Excel
                        </button>
                    </GridToolbar>
                    {/*<GridColumn field="user_id" title="User ID" width="100px"/>*/}

                    {categoryIds.includes('User') && <GridColumn field="name" title="Name" width="250px"/>}
                    {categoryIds.includes('Email') && <GridColumn field="email" title="Email" width="250px"/>}
                    {categoryIds.includes('Cell phone') &&
                        <GridColumn field="phone" title="Cell phone" width="250px"/>}
                    {categoryIds.includes('Access type') &&
                        <GridColumn field="accessType" title="Access type" width="250px"/>}
                    {categoryIds.includes('Course access') &&
                        <GridColumn field="standing" title="Course access" width="250px"/>}
                    {categoryIds.includes('Subscription reference') &&
                        <GridColumn field="reference" title="Subscription reference" width="250px"/>}
                    {categoryIds.includes('Subscription price') &&
                        <GridColumn field="price" title="Subscription price" width="250px"/>}
                    {categoryIds.includes('Total paid') &&
                        <GridColumn field="paid" title="Already paid" width="250px"/>}
                    {categoryIds.includes('Expected to pay') &&
                        <GridColumn field="expected" title="Expected to pay" width="250px"/>}
                    {categoryIds.includes('remaining to pay') &&
                        <GridColumn field="remaining" title="Remaining fees" width="250px"/>}
                    {categoryIds.includes('Payment date') &&
                        <GridColumn field="date" title="Payment date" width="250px"/>}

                </Grid>
            </ExcelExport>;
        }

    };

    useEffect(() => {
        showTransactions();
    }, []);
    const tabCategories = childCompRef2.current?.getValue();
    return (
        <div>
            <>
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Courses / Payment management"/>
                    <div className="container-fluid py-4">
                        <CourseStatistics/>
                        <div className="row">
                            <div className="col-lg-12 col-md-10 mx-auto">
                                <div className="card ">
                                    <div className="card-header p-2">
                                        <div className="row">
                                            <div className="col-4 col-md-5">
                                                <h5 className="mb-0">All subscriptions of {course?.name}</h5> <br/><br/>
                                            </div>
                                            {/*<div className="col-4 col-md-3 offset-md-4">*/}
                                            {/*    <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"*/}
                                            {/*          title="Click to view failed payment attempts">*/}
                                            {/*         <Link to={{*/}
                                            {/*             pathname: "/subscription-intent-details",*/}
                                            {/*             search: '?courseId=' + courseId*/}
                                            {/*         }} className="btn">*/}
                                            {/*            <button type="button"*/}
                                            {/*                    className="btn btn-success"*/}
                                            {/*            >*/}
                                            {/*                Go to subscription intents*/}
                                            {/*            </button>*/}
                                            {/*         </Link>*/}

                                            {/*    </span>*/}
                                            {/*</div>*/}

                                        </div>
                                        <div className="row">
                                            <div className="col-2 col-md-2 col-sm-2">
                                                <h6 className="mb-0">Filter</h6>
                                            </div>
                                            <div className="col-4 col-md-4 col-sm-6">
                                                <PlanList
                                                    parentCallback={setPlanId}
                                                    ref={childCompRef}
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-sm-2">
                                                <div className="form-group has-search">
                                                    <form>
                                                        <span className="fa fa-search form-control-feedback"></span>
                                                        <input type="text" className="form-control" placeholder="Search"
                                                               value={researchIndicator}
                                                               name="search"
                                                               onChange={(event) => setResearchIndicator(event.target.value)}
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-1 col-sm-2 col-md-1">
                                                <div className="form-group has-search">
                                                    <form>
                                                        <select className="form-control" id="myselectbox"
                                                                onClick={(e) => setElementPerPage(e.target.value)}
                                                            //value={elementPerPage}
                                                        >
                                                            <option value="5">5</option>
                                                            <option value="10" selected>10</option>
                                                            <option value="15">15</option>
                                                            <option value="20">20</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-1 col-md-2 col-sm-1">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={showTransactions}
                                                >
                                                    Apply filters
                                                </button>
                                                <button type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={clearAll}
                                                >Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-2 col-md-2 col-sm-2">
                                                <h6 className="mb-0">Data exportation</h6>
                                            </div>
                                            <div className="col-4 col-md-4 col-sm-6">
                                                {/*<PlanList*/}
                                                {/*    parentCallback={setPlanId}*/}
                                                {/*    ref={childCompRef}*/}
                                                {/* />*/}
                                                <MultiSelectItem
                                                    // parentCallback={setCategoryIds}
                                                    ref={childCompRef2}
                                                    //parentCallback={setCategoryIds}
                                                />
                                            </div>
                                            <div className="col-4 col-md-4 col-sm-3">

                                            </div>
                                            <div className="col-1 col-md-2 col-sm-1">
                                                <button type="button"
                                                        className="btn btn-info"
                                                        onClick={computeData}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                >Export current page
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-3">
                                        {/*<select className="form-select" aria-label="Default select example"*/}
                                        {/*        onChange={this.handleChange}>*/}
                                        {/*    <option selected>All user</option>*/}
                                        {/*    <option value={"having_paid"}>Users having paid everything</option>*/}
                                        {/*    <option value={"not_having_paid"}>Users not having paid everything*/}
                                        {/*    </option>*/}

                                        {/*</select>*/}
                                        <div className="table-responsive p-0">
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name
                                                        & Email
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Cell
                                                        phone
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Subscription
                                                        reference
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Subscription
                                                        price
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">TOTAL
                                                        PAID
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Expected
                                                        to pay
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Remaining
                                                        to pay
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Access
                                                        type
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Course
                                                        access
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Payment
                                                        date
                                                    </th>
                                                    {/*<th className="text-secondary opacity-7">...</th>*/}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    isLoading ? (
                                                            <div className="spinner-border text-primary"
                                                                 role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        ) :
                                                        null
                                                }
                                                {/*{*/}
                                                {/*    clickLoading ?*/}
                                                {/*        <div className="spinner-border text-primary" role="status">*/}
                                                {/*            <span className="sr-only">Loading...</span>*/}
                                                {/*        </div> : null*/}
                                                {/*}*/}
                                                {subscriptions && subscriptions.map((subscription) => (
                                                    <tr key={subscription.id}>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                {/*<div>*/}
                                                                {/*    <img src="../assets/img/team-2.jpg"*/}
                                                                {/*         className="avatar avatar-sm me-3 border-radius-lg"*/}
                                                                {/*         alt="user1"/>*/}
                                                                {/*</div>*/}
                                                                <div
                                                                    className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-sm">{subscription.name}</h6>
                                                                    <p className="text-xs text-secondary mb-0">{subscription.email}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold ">{subscription.phone}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold ">#{subscription.reference}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold ">{subscription.price}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{subscription.actualPaidAmount}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{subscription.remainingAmount}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{subscription.remainingAmount}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">
                                                                {subscription.accessType === 'MANUAL' && <span
                                                                    className="badge badge-success"> {subscription.accessType}
                                                                </span>}
                                                                {subscription.accessType === 'NORMAL' && <span
                                                                    className="badge badge-pill badge-primary">{subscription.accessType}</span>}
                                                                {subscription.accessType === 'CANCELLED' &&
                                                                    <span
                                                                        className="badge badge-pill badge-warning">{subscription.accessType}</span>}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">
                                                                {subscription.isInGoodStanding ? <span
                                                                        className="badge badge-pill badge-success"> Access to course</span> :
                                                                    <span
                                                                        className="badge badge-pill badge-danger">No access to course</span>}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">
                                                                {moment(subscription.created_at).utc().format('DD-MM-YYYY') + ' at ' + moment.utc(subscription.created_at).format("HH:mm")}</p>

                                                        </td>
                                                        {/* <td className="align-middle text-center text-sm">
                                                                <p className="text-xs font-weight-bold mb-0">{subscription.price} $</p>
                                                            </td>

                                                            <td className="align-middle text-center">
                                                                <span
                                                                    className="badge badge-sm bg-gradient-success">Pay in {subscription.number_installment} Installment</span>
                                                            </td>*/}

                                                        <td className="align-middle">
                                                            <button type="button"
                                                                    className="btn btn-secondary"
                                                                    id={subscription.user_id}
                                                                    onClick={openDetailsTransaction}>Details
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row justify-content-center">*/}
                        {/*    <div className="col-6">*/}
                        {/*        {isLoading ? (*/}
                        {/*                <div className="spinner-border text-primary" role="status">*/}
                        {/*                    <span className="sr-only">Loading...</span>*/}
                        {/*                </div>*/}

                        {/*            ) :*/}
                        {/*            null}*/}
                        {/*    </div>*/}
                        {/*    /!*<div className="col-6">*!/*/}
                        {/*    /!*    <p align="right">{totalItems ? start + "-" + limit + " of " + totalItems : null}</p>*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*</div>*/}
                        <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">

                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Data Exportation
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {exportInExcel(transformData(subscriptions))}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AdminFooter/>
                    </div>
                    <div className="card-footer py-4">
                        <nav aria-label="...">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={'...'}
                                pageCount={lastPage}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={6}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-end mb-0"}
                                pageClassName={"page-item"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                pageLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </nav>
                    </div>
                </main>
            </>
        </div>
    )

}

export default UserTransactionCourse;
