import React, { useEffect, useState } from "react";
import { LOGO_WHITE } from "../../../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../logo/Logo";
import routes from "../../../../routes/routes";
import { getUser, isDiscountEligibility } from "../../../../network/auth/Auth";
// import UserProfileDropdown from "../avatar/UserProfileDropdown";
import { Sidebar } from "primereact/sidebar";
import UserProfileDropdownPrime from "../avatar/UserProfileDropdownPrime";
import { logoutRedirectUrl } from "../../../../constants/Constants";

const Header = () => {
    /**
     * States
     */
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState(location?.pathname);
    const [user, setUser] = useState(null);

    const [visible, setVisible] = useState(false);
    const loadData = () => {
        let user = getUser();
        setUser(user);
    };

    /**
     * Comportments
     */
    let allRoutes = [];
    routes.map(({ spaceName, secure, role, routes }) => {
        return routes.map(({ path, component }) => {
            return allRoutes.push({
                path: path,
                component: component,
                role: role,
                secure: secure,
            });
        });
    });
    const genericHamburgerLine = `tw-h-[3px] tw-w-[30px] tw-my-1 tw-rounded-full tw-bg-white tw-transition tw-ease-in-out tw-transform tw-duration-300`;

    const navigate = useNavigate();
    const handleLogOut = (e) => {
        // e.preventDefault();
        // logout();
        // navigate("/");

        e.preventDefault();
        logoutRedirectUrl();
    };

    /**
     * Hooks
     */
    useEffect(() => {
        loadData();
        setCurrentRoute(location.pathname.split("/").slice(0, 3).join("/"));
    }, [location?.pathname]);

    const isEligible = isDiscountEligibility() ? (isDiscountEligibility()).isEligibleToDiscount : false;
    /**
     * render
     */
    return (
        <>
            <nav
                className="tw-flex tw-items-center tw-justify-between tw-w-full tw-h-[71px] tw-text-primary tw-px-[24px] tw-bg-primary sm:tw-bg-white">
                <div>
                    {/* Header - Large */}
                    <div className="tw-items-center tw-hidden tw-space-x-3 sm:tw-flex">
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="tw-w-10 tw-h-10"
                            >
                                <path
                                    d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
                                <path
                                    d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
                                <path
                                    d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
                            </svg>
                        </span>
                        <span className="tw-text-sm tw-font-semibold sm:tw-text-lg">
                            STUDENT SPACE
                        </span>
                    </div>

                    {/* Utrains Logo - Mobile */}
                    <span className="sm:tw-hidden">
                        <Logo src={LOGO_WHITE} classes={"tw-h-[40px]"} />
                    </span>
                </div>

                <div>
                    {/* Dropdown Navigation */}
                    <div className="tw-items-center tw-hidden sm:tw-flex">
                        {/* <UserProfileDropdown user={user} /> */}
                        <UserProfileDropdownPrime user={user} />
                    </div>

                    {/* Menu - Mobile */}
                    <div
                        className="tw-flex tw-items-center tw-justify-between tw-py-8 tw-border-b tw-border-gray-400 sm:tw-hidden tw-text-primary ">
                        <nav>
                            <section className="tw-flex lg:tw-hidden">
                                <div className="tw-flex tw-card tw-justify-content-center ">
                                    <Sidebar
                                        visible={visible}
                                        onHide={() => setVisible(false)}
                                        className="tw-bg-prinary"
                                    >
                                        <div className="tw-flex tw-flex-col tw-w-full tw-h-full">
                                            <div
                                                className="tw-flex tw-overflow-y-auto tw-flex-col tw-justify-between tw-flex-1 tw-transition tw-ease-in-out tw-delay-100 tw-duration-200">
                                                <nav>
                                                    <div
                                                        className={`tw-justify-start tw-pl-[10px] tw-text-[12px] tw-flex tw-h-[18px] sm:tw-mb-3 tw-truncate ...`}
                                                    >
                                                        <span className="tw-text-[#C2C2C2] tw-uppercase">
                                                            Main Menu
                                                        </span>
                                                    </div>

                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[10px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/mysessions" ===
                                                                allRoutes.find((item) => {
                                                                    return item?.path === currentRoute;
                                                                })?.path ||
                                                                "/workspace" ===
                                                                allRoutes.find((item) => {
                                                                    return item?.path === currentRoute;
                                                                })?.path
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        // to="/workspace/mysessions"
                                                        to={process.env.REACT_APP_COURSE_LMS + "/my-sessions"}
                                                    >
                                                        <i
                                                            tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="My sessions"
                                                            className="pi pi-calendar"
                                                            style={{ fontSize: "1.32rem" }}
                                                        ></i>
                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            My sessions
                                                        </span>
                                                    </Link>

                                                    {process.env.REACT_APP_SIMPLE_COURSE === 'OPENED' ?
                                                        <Link
                                                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/mycourses" ===
                                                                    allRoutes.find((item) => {
                                                                        return item?.path === currentRoute;
                                                                    })?.path
                                                                    ? " tw-bg-primary-200 tw-text-primary"
                                                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                                } `}
                                                            // to="/workspace/mycourses"
                                                            to={process.env.REACT_APP_COURSE_LMS + "/my-courses"}
                                                        >
                                                            <i
                                                                tabIndex="0"
                                                                data-toggle="tooltip"
                                                                title="My Courses"
                                                                className="pi pi-book"
                                                                style={{ fontSize: "1.32rem" }}
                                                            ></i>
                                                            <span
                                                                className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                            >
                                                                My Courses
                                                            </span>
                                                        </Link> : ''}

                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/next-sessions" ===
                                                                allRoutes.find((item) => {
                                                                    return item?.path === currentRoute;
                                                                })?.path
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/next-sessions"
                                                    >
                                                        <i
                                                            tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="Next sessions"
                                                            className="pi pi-clock"
                                                            style={{ fontSize: "1.32rem" }}
                                                        ></i>
                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            Next sessions
                                                        </span>
                                                    </Link>
                                                    {process.env.REACT_APP_SIMPLE_COURSE === 'OPENED' ?
                                                        <Link
                                                            className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/courses" ===
                                                                    allRoutes.find((item) => {
                                                                        return item?.path === currentRoute;
                                                                    })?.path
                                                                    ? " tw-bg-primary-200 tw-text-primary"
                                                                    : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                                } `}
                                                            to="/workspace/courses"
                                                        >
                                                            <i
                                                                tabIndex="0"
                                                                data-toggle="tooltip"
                                                                title="Available Courses"
                                                                className="pi pi-check-circle"
                                                                style={{ fontSize: "1.32rem" }}
                                                            ></i>
                                                            <span
                                                                className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                            >
                                                                Available Courses
                                                            </span>
                                                        </Link> : ''}

                                                    <div
                                                        className={`tw-justify-start tw-pl-[10px]  tw-text-[12px] tw-flex tw-pb-[18px] tw-pt-[12px] tw-h-[24px] tw-mb-1`}
                                                    >
                                                        <span className="tw-text-[#C2C2C2] tw-uppercase">
                                                            Payments
                                                        </span>
                                                    </div>

                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mt-2  tw-rounded-md  ${"/workspace/transactions" ===
                                                                allRoutes.find((item) => {
                                                                    return item?.path === currentRoute;
                                                                })?.path
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/transactions"
                                                    >
                                                        <i
                                                            tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="My transactions"
                                                            className="pi pi-credit-card"
                                                            style={{ fontSize: "1.32rem" }}
                                                        ></i>
                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            My transactions
                                                        </span>
                                                    </Link>

                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-mt-3  tw-rounded-md  ${"/workspace/make-payment" ===
                                                                allRoutes.find((item) => {
                                                                    return item?.path === currentRoute;
                                                                })?.path
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/make-payment"
                                                    >
                                                        <i
                                                            tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="Make a payment"
                                                            className="pi pi-wallet"
                                                            style={{ fontSize: "1.32rem" }}
                                                        ></i>
                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            Make a payment
                                                        </span>
                                                    </Link>

                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mt-2  tw-rounded-md  ${"/workspace/profile?tab=2" === currentRoute
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/profile?tab=2"
                                                    >
                                                        <i
                                                            tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="Payments method"
                                                            className="pi pi-dollar"
                                                            style={{ fontSize: "1.32rem" }}
                                                        ></i>

                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            Payment method
                                                        </span>
                                                    </Link>

                                                    <div
                                                        className={`tw-justify-start tw-pl-[10px]  tw-text-[12px] tw-flex tw-pb-[18px] tw-pt-[12px] tw-h-[24px] tw-mb-1`}
                                                    >
                                                        <span className="tw-text-[#C2C2C2] tw-uppercase">
                                                            Security
                                                        </span>
                                                    </div>
                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-mt-3  tw-rounded-md  ${"/workspace/profile?tab=1" === currentRoute
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/profile?tab=1"
                                                    >
                                                        <i
                                                            tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="Change password"
                                                            className="pi pi-wallet"
                                                            style={{ fontSize: "1.32rem" }}
                                                        ></i>

                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            Change Password
                                                        </span>
                                                    </Link>

                                                    <div
                                                        className={`tw-justify-start tw-pl-[10px]  tw-text-[12px] tw-flex tw-pb-[18px] tw-pt-[12px] tw-h-[24px] tw-mb-1`}
                                                    >
                                                        <span className="tw-text-[#C2C2C2] tw-uppercase">
                                                            Promotion
                                                        </span>
                                                    </div>
                                                    {isEligible && <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-rounded-md  ${"/workspace/ask-discount" ===
                                                                allRoutes.find((item) => {
                                                                    return item?.path === currentRoute;
                                                                })?.path
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/ask-discount"
                                                    >
                                                        <i tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title="Ask a discount" className="pi pi-money-bill"
                                                            style={{ fontSize: "1.32rem" }}></i>
                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            Ask a discount
                                                        </span>
                                                    </Link>}

                                                    <Link
                                                        className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2 tw-mt-3  tw-rounded-md  ${"/workspace/delay?tab=1" === currentRoute
                                                                ? " tw-bg-primary-200 tw-text-primary"
                                                                : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                            } `}
                                                        to="/workspace/delay"
                                                    >
                                                        <i tabIndex="0"
                                                            data-toggle="tooltip"
                                                            title=" Ask a delay" className="pi pi-calendar-plus"
                                                            style={{ fontSize: "1.32rem" }}></i>
                                                        <span
                                                            className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                        >
                                                            Postpone payment date
                                                        </span>
                                                    </Link>
                                                </nav>
                                            </div>
                                            <hr className="tw-my-3 tw-border-gray-200" />
                                            <Link
                                                className={`tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-mb-2  tw-rounded-md  ${"/workspace/profile" ===
                                                        allRoutes.find((item) => {
                                                            return item?.path === currentRoute;
                                                        })?.path
                                                        ? " tw-bg-primary-200 tw-text-primary"
                                                        : "tw-text-white hover:tw-bg-primary-200 hover:tw-text-gray-700 tw-transition-colors tw-duration-300 tw-transform"
                                                    } `}
                                                to="/workspace/profile"
                                            >
                                                <i
                                                    tabIndex="0"
                                                    data-toggle="tooltip"
                                                    title="My Profile"
                                                    className="pi pi-user"
                                                    style={{ fontSize: "1.32rem" }}
                                                ></i>

                                                <span
                                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                >
                                                    My Profile
                                                </span>
                                            </Link>
                                            <Link
                                                className="tw-flex tw-items-center tw-px-[9px] tw-py-1 tw-text-red-400 tw-transition-colors tw-duration-300 tw-transform tw-rounded-md hover:bg-red-200 hover:tw-text-red-700"
                                                onClick={handleLogOut}
                                            >
                                                <i
                                                    tabIndex="0"
                                                    data-toggle="tooltip"
                                                    title="Logout"
                                                    className="pi pi-sign-out"
                                                    style={{ fontSize: "1.32rem" }}
                                                ></i>

                                                <span
                                                    className={`tw-mx-2 tw-font-medium tw-text-sm tw-truncate ...`}
                                                >
                                                    Logout
                                                </span>
                                            </Link>
                                        </div>
                                    </Sidebar>
                                    <div
                                        className="tw-space-y-2 tw-text-white"
                                        onClick={() => setVisible((prev) => !prev)}
                                    >
                                        {!visible && (
                                            <div
                                                className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-border-none tw-group">
                                                <div
                                                    className={`${genericHamburgerLine} tw-opacity-100 group-hover:tw-opacity-100`}
                                                />
                                                <div
                                                    className={`${genericHamburgerLine} tw-opacity-100 group-hover:tw-opacity-100`}
                                                />
                                                <div
                                                    className={`${genericHamburgerLine} tw-opacity-100 group-hover:tw-opacity-100`}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </nav>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
